/*
* 업무구분: 종합계좌번호 콤보화면
* 화 면 명: MSPTS95150D
* 화면설명: 종합계좌번호 콤보화면
* 작 성 일: 2023.05.10
* 작 성 자: 지재호 
* 파일명 : TPUP95150.xfdl
*/
<template>
  <ur-box-container direction="column" alignV="start">
    
    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

  </ur-box-container>
</template>
<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDatasetTemp = {  
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    gdC: '',
    gdNm: '',
    trstTypC: '',
    cnm: '',
    rcno: '',
    rcnoKindTc: '',
    accStatC: '',
    bnkbPswd: '',
  }

  const defaultDsSearch = { 
    tacno: '',
    bnkbSeq: '',
    accStatC: '',
    brigAccYn: '',
  }

  const defaultDsList = { 
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    accStatC: '',
    gdNm: '',
    cnm: '',
    brigAccYn: '',
    curC: '',
    contDate: '',
    mtrtDate: '',
    trstTypC: '',
    gdC: '',
    trstAmt: '',
    gdTypDtlC: '',
    mngBrcd: '',
    csId: '',
    admkNm: '',
    bnkb: '',
    acc: '',
    fundC: '',
    rcno: '',
    rcnoKindTc: '',
    soptTc: '',
  }

  const defaultDsMsData = { 
    msData: '',
    pbprNo: '',
    msUseYn: '',
  }

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS95150D",
    screenId: "MSPTS95150D",
    components: {
      'ts-alert-popup': TSAlertPopup,
    },
    props: {
      ds_search: {type:Object, default:null},
      ds_list: {type:Object, default:null},
      main_page: {type:String, default:null},
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // this.dSearchObj = Object.assign({}, this.propsObj) 
    },
    mounted() {
      this.fn_afterFormOnload()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 

        // 팝업 데이터 객체
        pPopup206Obj: {},   // 계좌번호 조회
        pAlertPopupObj: {}, // 공통 객체
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      
      /***************************************************************************************
          실질적인 화면 초기화 
      *****************************************************************************************/
      fn_afterFormOnload ()
      {
        // fn_userInit(); // 데이터 초기화 => 부모에서 초기화로 변경
      },

      /************************************************************************************************
       * 데이터 초기화 
       * 부모 fn_init 에서 초기화함. 사용X
       ************************************************************************************************/
      fn_userInit () 
      {

        this.Edt_acno.set_value("");
        this.edt_cnm.set_value("");
        this.ds_list.clearData();
        this.ds_search.clearData();	
      },

      /************************************************************************************************
       * 화면 조회 값 설정 
       ************************************************************************************************/
      _setValue ( strAcno,strCnm, strPopCls )
      {
        // 계좌명 
        var varCnm = new String(strCnm);	
        var varAcno = new String(strAcno);
        
        // 종합계좌번호와 고객명
        if ( varAcno.trim().length == 7 ) {
          this.Edt_acno.set_value(varAcno);
          fn_transaction(varAcno);
        }

      },

      /************************************************************************************************
       *  값 리턴 
       ************************************************************************************************/
      _getValue ()
      {	
        var objRtnArr = new Array(17); //팝업창 close시 전달할 값을 위한 배열 선언
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.cnm)){	

          objRtnArr[0]  = this.ds_list.csId             ; // 고객ID                   
          objRtnArr[1]  = this.ds_list.cno              ; // 고객번호                 
          objRtnArr[2]  = this.ds_list.cnm              ; // 고객명                   
          objRtnArr[3]  = this.ds_list.rcnoKindTc       ; // 실명구분                 
          objRtnArr[4]  = this.ds_list.rcno             ; // 실명번호                 
          objRtnArr[5]  = this.ds_list.cono             ; // 사업자번호               
          objRtnArr[6]  = this.ds_list.genTaxtTc        ; // 일반과세구분코드         
          objRtnArr[7]  = this.ds_list.persnBusiMnBsno  ; // 개인사업자사업자등록번호 
          objRtnArr[8]  = this.ds_list.corpYn           ; // 법인여부                 
          objRtnArr[9]  = this.ds_list.csIvtdRatingC    ; // 고객투자성향등급코드     
          objRtnArr[10] = this.ds_list.procDt           ; // 처리일시                
          objRtnArr[11] = this.ds_list.valdTrmYys       ; // 유효기간년수            
          objRtnArr[12] = this.ds_list.ques10           ; // 파생상품투자기간        
          objRtnArr[13] = this.ds_list.acno             ; // 종합계좌번호            
          objRtnArr[14] = this.ds_list.qtnSeq           ; // 설문일련번호                       
          objRtnArr[15] = this.ds_list.regDate          ; // 고객등록일자   
          objRtnArr[16] = this.ds_list.tassIssncAccYn   ; // TASS발급계좌여부

          
          return objRtnArr;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
        
      },
      /************************************************************************************************
       * 종합계좌번호 값 리턴 
       ************************************************************************************************/
      _getTacno ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.cnm)){	
          var oParamData = this.ds_search.Edt_acno
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },
        
      /************************************************************************************************
       * 고객명 값 리턴 
       ************************************************************************************************/
      _getCnm ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.cnm)){	
          var oParamData = this.ds_list.cnm
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 고객 ID 값 리턴 
       ************************************************************************************************/
      _getCsId ()
      {	
      
        // 데이터가 있는경우 
      
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.csId)){	
          var oParamData = this.ds_list.csId
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 고객 ID 값 리턴 (종합계좌 미개설 고객)  2021-11-23 add
       ************************************************************************************************/
      _getCsIdSuperDo ()
      {
      
        // 데이터가 있는경우 
      
        if(!TSCommUtil.gfn_isNull(this.ds_list.csId)){	
          var oParamData = this.ds_list.csId
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }
      },

      /************************************************************************************************
       * 고객번호 값 리턴 
       ************************************************************************************************/
      _getCno ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.cno)){	
          var oParamData = this.ds_list.cno
          return oParamData;
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 실명구분 값 리턴  
       ************************************************************************************************/
      _getRcnoKindTc ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.rcnoKindTc)){	
          var oParamData = this.ds_list.rcnoKindTc
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 실명번호 값 리턴 
       ************************************************************************************************/
      _getRcno ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno)  && !TSCommUtil.gfn_isNull(this.ds_list.rcno)){	
          var oParamData = this.ds_list.rcno;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 법인등록번호  값 리턴 
       ************************************************************************************************/
      _getCono ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno)  && !TSCommUtil.gfn_isNull(this.ds_list.cono)){	
          var oParamData = this.ds_list.cono;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 일반과세구분코드 값 리턴 
       ************************************************************************************************/
      _getGenTaxtTc ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.genTaxtTc) ){	
          var oParamData = this.ds_list.genTaxtTc
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 개인사업자사업자등록번호 값 리턴 
       ************************************************************************************************/
      _getPersnBusiMnBsno ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.persnBusiMnBsno) ){	
          var oParamData = this.ds_list.persnBusiMnBsno;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 법인여부 값 리턴 
       ************************************************************************************************/
      _getCorpYn ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.corpYn) ){	
          var oParamData = this.ds_list.corpYn;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 고객투자성향등급코드 값 리턴 
       ************************************************************************************************/
      _getCsIvtdRatingC ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.csIvtdRatingC)  ){	
          var oParamData = this.ds_list.csIvtdRatingC;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 처리일시 값 리턴 
       ************************************************************************************************/
      _getProcDt ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.procDt) ){	
          var oParamData = this.ds_list.procDt;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 유효기간년수 값 리턴 
       ************************************************************************************************/
      _getValdTrmYys ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno)  && !TSCommUtil.gfn_isNull(this.ds_list.valdTrmYys) ){	
          var oParamData = this.ds_list.valdTrmYys;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 파생상품투자기간 값 리턴 
       ************************************************************************************************/
      _getQues10 ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.ques10) ){	
          var oParamData = this.ds_list.ques10;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 설문일련번호 값 리턴 
       ************************************************************************************************/
      _getQtnSeq ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.qtnSeq) ){	
          var oParamData = this.ds_list.qtnSeq;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 종합계좌번호 + 통장일련번호 값 리턴 
       ************************************************************************************************/
      _getTacnoBnkbSeq ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.tacnoBnkbSeq) ){	
          var oParamData = this.ds_list.tacnoBnkbSeq;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 고객등록일자 값 리턴 
       ************************************************************************************************/
      _getRegDate ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) && !TSCommUtil.gfn_isNull(this.ds_list.regDate) ){	
          var oParamData = this.ds_list.regDate;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },


      /************************************************************************************************
       * TASS발급계좌여부 값 리턴 
       ************************************************************************************************/
      _getTassIssncAccYn ()
      {	
        // 데이터가 있는경우 
        if(!TSCommUtil.gfn_isNull(this.ds_list.tassIssncAccYn) && !TSCommUtil.gfn_isNull(this.ds_list.tassIssncAccYn) ){	
          var oParamData = this.ds_list.tassIssncAccYn;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return "";
        }	
      },

      /************************************************************************************************
       * 계좌 정보 조회 TR 전송 
       ************************************************************************************************/
      fn_transaction ( acnoValue )
      {
        // 계좌번호 조회 
        this.ds_search.tacno = acnoValue

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600215_S'
        this.eaiCommObj.params = Object.assign({},this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
      
        // var sSvcID        = "selectTPUP95320";
        // var sURL          = "svc::selectTPUP95320.do";
        // var sInDatasets   = "tpup95320=ds_search";
        // var sOutDatasets  = "ds_list=tpup95320Rst";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      /************************************************************************************************
       * 계좌조회 정상응답 후 처리 
       ************************************************************************************************/
      fn_callBack ( res )
      {
         // 응답코드가 정상이 아닌경우 
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        Object.assign(this.ds_list, resData)

        // 
        /**
         * 메인 페이지가 개인고객 등록일 경우 실행 
         * ASIS acnoCallBackFunc 함수 대응 기존에 메인에 있었지만 여기서 정리후 메인으로 보낸다
         */
        if (this.main_page == 'MSPTS100M') {
          let pObj = {
            gTacno          : this._getTacno(),
            gCorpYn         : this._getCorpYn(),
            gCsId           : this._getCsId(),
            gCnm            : this._getCnm(),
            gTassIssncAccYn : this._getTassIssncAccYn(),
            gParentFileNm   : "BM3000", // 부모화면파일명
          }
          this.$emit('ts-acno-call-back-func', pObj)
        }
        
        // if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_list.cnm ) ) ) {
        // //if ( this.ds_list.rowcount > 0 ) {
        //   try{
        //     this.lookupFunc("acnoCallBackFunc").call();
        //   } catch(e){
        //   }	
        // }
        
      },

      /************************************************************************************************
       * 계좌번호 입력 시 
       ************************************************************************************************/
      Edt_acno_onkeyup ()
      {
        var acnoValue = this.ds_search.Edt_acno;

        // 계좌번호 10자리 입력시 자동 조회 
        if ( acnoValue.trim().length == 7 ) {
          fn_transaction(acnoValue);
        }
        else {
          // 계좌번호 입력 후 엔터 시 계좌 조회 tr 호출 
          if ( e.keycode == 13 ) {
            if ( acnoValue.trim().length != 7 ) {
              //this.alert("계좌번호를 다시 입력하세요.");
              this.Edt_acno.setFocus();
            }
            else {
              fn_transaction(acnoValue);
            }
          }
        }	
      },

      /************************************************************************************************
       * 고객정보 조회 팝업 호출 
       ************************************************************************************************/
      Button11_onclick ()
      {
        var oArg           = {pID:"TPUP95320", dsArg:this.ds_inAcno};  //팝업화면으로 보낼 파라미터.
        var sOption        = "autosize=true,title=true";           //팝업열때 옵션
        var sPopupCallBack = "fn_popupAfter";                      //팝업화면에서 보낸 파라미터 받는곳
        
        this.gfn_openPopup("TPUP9532011","popup::TPUP95320.xfdl",oArg,sOption,sPopupCallBack);	
      },

      /************************************************************************************************
       * 고객정보 조회 팝업 호출 
       ************************************************************************************************/
      fn_popupAfter ( strId, strVal )
      {
        
        var arrVal = new Array(18);
        if(TSCommUtil.gfn_isNull(strVal)){
          return;
        }else{
          arrVal = strVal.split(",");
        }
        this.ds_list.clearData();
        var nRow = this.ds_list.addRow();
          
        this.ds_list.setColumn(nRow, "csId"				, arrVal[0]); // 고객ID
        this.ds_list.setColumn(nRow, "cno"				, arrVal[1]); // 고객번호 
        this.ds_list.setColumn(nRow, "cnm"				, arrVal[2]);	
        this.ds_list.setColumn(nRow, "rcnoKindTc"		, arrVal[3]); // 실명구분 
        this.ds_list.setColumn(nRow, "rcno"				, arrVal[4]); // 실명번호 
        this.ds_list.setColumn(nRow, "cono"				, arrVal[5]); // 사업자번호  
        this.ds_list.setColumn(nRow, "genTaxtTc"		, arrVal[6]); // 일반과세구분코드 
        this.ds_list.setColumn(nRow, "persnBusiMnBsno"	, arrVal[7]); // 개인사업자사업자등록번호 
        this.ds_list.setColumn(nRow, "corpYn"			, arrVal[8]); // 법인여부 
        this.ds_list.setColumn(nRow, "csIvtdRatingC"	, arrVal[9]); // 고객투자성향등급코드 
        this.ds_list.setColumn(nRow, "procDt"			, arrVal[10]); // 처리일시  
        this.ds_list.setColumn(nRow, "valdTrmYys"		, arrVal[11]); // 유효기간년수 
        this.ds_list.setColumn(nRow, "ques10"			, arrVal[12]); 	// 파생상품투자기간 
        this.ds_list.setColumn(nRow, "tacno"			, arrVal[13]);	// 종합계좌번호 
        this.ds_list.setColumn(nRow, "age"			    , arrVal[14]);	// 나이 
        this.ds_list.setColumn(nRow, "tacnoBnkbSeq"		, arrVal[15]);	// 종합계좌번호 + 통장일련버호 
        this.ds_list.setColumn(nRow, "regDate"			, arrVal[16]);	// 고객가입일자
        this.ds_list.setColumn(nRow, "tassIssncAccYn"	, arrVal[17]);	// TASS발급계좌여부
        
        
        this.Edt_acno.set_value(arrVal[13]); // 종합계좌번호 
        this.edt_regDate.set_value(arrVal[16]); // 고객가입일자 
        trace("16");
      
          // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 
        if ( this.ds_list.rowcount > 0 ) {
          try{
            this.lookupFunc("acnoCallBackFunc").call();
          } catch(e){
          }	
        }
      },

      /************************************************************************************************
       * 데이터 변경시 계좌일련번호 콤보와 고객명 초기화 
       ************************************************************************************************/
      Edt_acno_ontextchanged ()
      {
        if ( this.ds_list.rowcount > 0 ) {
          try{
            this.lookupFunc("acnoClearCallBackFunc").call();
          } catch(e){
          }	
          this.ds_list.clearData();	
        }
      },

      TPUP95150_onactivate ()
      {
        this.Edt_acno.setFocus(); // 계좌번호 포커스 
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm
              } else {
                this.pAlertPopupObj.confirm = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              } else {
                this.pAlertPopupObj.confirmFunc = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              } else {
                this.pAlertPopupObj.closeFunc = ''
              }
            }
            break
        }

        this.$refs.alertPopup.fn_Open()
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    }
    
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
