/*
* 업무구분: 신탁>고객/상품>고객정보조회>고객별보유현황조회
* 화 면 명: MSPTS103M
* 화면설명: 고객별보유현황조회 [2600]
* 작 성 일: 2023.03.28
* 작 성 자: 지재호
* 파 일 명: TFSL80700.xfdl
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="고객별보유현황조회" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0 gap40">
                <div class="wrap-input row mr40">
                  <label> 보유일자 </label>
                  <mo-date-picker class="input-long w130" :bottom="false"
                    v-model="cal_baseDate" 
                    @keyup="fn_validErrRemoveInput($event, 'edt_cal_baseDate')"
                    ref="edt_cal_baseDate"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 종합계좌번호 </label>
                  <mo-decimal-field class="w130"
                    numeric 
                    mask="#######"
                    v-model="ds_search.Edt_acno"  
                    @keyup="fn_SearchValid($event)" 
                    @paste="fn_SearchValid($event, 'P')"
                    ref="Edt_acno"
                  />
                  <mo-button class="btn-pop-download" @click="fn_OpenPopup(0)" > </mo-button>
                  <mo-text-field class="w130"
                    v-model="ds_list.cnm"
                    ref="edt_cnm"
                    disabled
                  />
                </div>
                <div class="wrap-input row">
                  <label> 비밀번호 </label>
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    numeric
                    class="w80"
                    v-model="ds_search.pwd"
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt"         
                    ref="edt_pwd"
                  />
                  <mo-text-field class="w130"
                    v-else
                    type="password" 
                    maxlength="4"
                    v-model="ds_search.pwd"
                    @keyup="pwd_onkeyup($event, 'edt_pwd')"
                    ref="edt_pwd" 
                  />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w70p gap40">
                <div class="wrap-input row flex-unset mr50">
                  <label class="w61"> 구분 </label>
                   <mo-radio-wrapper :items="list_rdo_selType" v-model="rdo_selType" class="row"/>
                </div>
                <div class="wrap-input row flex-unset">
                  <label class="w61"> 계좌상태 </label>
                  <mo-radio-wrapper :items="list_rdo_accStatC" v-model="rdo_accStatC" class="row"/>
                </div>
              </div>
              <div class="right w30p"></div>
            </div>
            <div class="row w100p">
              <div class="left w70p gap40">
                <div class="wrap-input row flex-unset mr50">
                  <label class="w61"> 무잔고포함 </label>
                   <mo-radio-wrapper :items="list_rdo_balTyp" v-model="rdo_balTyp" class="row"/>
                </div>
                <div class="wrap-input row flex-unset" v-show="visibleList.rdo_prrtTyp==true">
                  <label class="w61"> 수익률 </label>
                   <mo-radio-wrapper :items="list_rdo_prrtTyp" v-model="rdo_prrtTyp" ref="rdo_prrtTyp" class="row"/>
                </div>
              </div>
              <div class="right w30p">
                <div class="wrap-button row">
                  <mo-button @click="fn_init"> 초기화 </mo-button>
                  <mo-button primary @click="fn_searchList"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title"> 운용자산현황 </h2>
        </div>
        <div class="right-bg-white">
          <div class="wrap-table table-sticky sticky-t05">
            <table class="table row-type wsN" v-show="visibleList.grd_contract_baseDate==true">
              <!-- grd_contract_baseDate -->
              <thead>
                <tr>
                  <th> 계좌번호 </th>
                  <th> 상품명 </th>
                  <th> 전일신탁잔고 </th>
                  <th> 입금금액 </th>
                  <th> 해지금액 </th>
                  <th> 잔고금액 </th>
                  <th> 계좌상태 </th>
                  <th> 대리인여부 </th>
                  <th> 대리인 </th>
                  <th> 위임기간 </th>
                  <th> 계약일 </th>
                  <th> 만기일 </th>
                  <th> 관리점 </th>
                  <th> 관리자 </th>
                </tr>
              </thead>
              <tbody>
                <!-- ds_contract_baseDate -->
                <tr v-for="(row, i) in ds_contract_baseDate" :key="i">
                  <td>{{$tsUtil.gfn_accnoReplace(row.acctNo)}}</td>                       
                  <td class="aL">{{row.gdNm}}</td>           
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.befTrstAmt))}}</td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.totAddAmt))}}</td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.tmtlTcurAmt))}}</td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.trstAmt))}}</td>
                  <td> <mo-badge class="badge-type possible type03" :text="row.accStatNm" /> </td>
                  <td>{{row.agntRegYn}}</td>
                  <td>{{row.agntNm}}</td>
                  <td>{{$tsUtil.gfn_dateReplace(1, row.athzDate)}}</td>
                  <td>{{$tsUtil.gfn_dateReplace(1, row.contDate)}}</td>
                  <td>{{$tsUtil.gfn_dateReplace(1, row.mtrtDate)}}</td>
                  <td>{{row.mngBrcdNm}}</td>      
                  <td>{{row.mngrEmnoNm}}</td>     
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td> 합계 </td>
                  <td>  </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_baseDate.befTrstAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_baseDate.totAddAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_baseDate.tmtlTcurAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_baseDate.trstAmt)}} </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                </tr>
              </tfoot>
            </table>

            <table class="table row-type wsN" v-show="visibleList.grd_contract==true">
              <thead>
                <tr>
                  <th>계좌번호</th>
                  <th>상품명</th>
                  <th>신탁금액</th>
                  <th>평가금액</th>
                  <th>신탁이익</th>
                  <th>{{prrtTypText}}</th>
                  <th>계좌상태</th>
                  <th>대리인여부</th>
                  <th>대리인</th>
                  <th>위임기간</th>
                  <th>계약일</th>
                  <th>만기일</th>
                  <th>관리점</th>
                  <th>관리자</th>
                </tr>
              </thead>
              <tbody>
                <!-- ds_contract -->
                <tr v-for="(row, i) in ds_contract" :key="i">
                  <td> {{$tsUtil.gfn_accnoReplace(row.acctNo)}} </td>   <!-- 1000087-001-001 -->
                  <td class="aL"> {{row.gdNm}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.trstAmt))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmAmt))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmPlSum))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmPrrt).toFixed(2))}} </td>
                  <td> <mo-badge class="badge-type possible type03" :text="row.accStatNm" /> </td>
                  <td> {{row.agntRegYn}} </td>
                  <td> {{row.agntNm}} </td>
                  <td> {{$tsUtil.gfn_dateReplace(1, row.athzDate)}} </td>
                  <td> {{$tsUtil.gfn_dateReplace(1, row.contDate)}} </td>
                  <td> {{$tsUtil.gfn_dateReplace(1, row.mtrtDate)}} </td>
                  <td> {{row.mngBrcdNm}} </td>
                  <td> {{row.mngrEmnoNm}} </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td> 합계 </td>
                  <td>  </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract.trstAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract.estmAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract.estmPlSum)}} </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                </tr>
              </tfoot>
            </table>

            <table class="table row-type wsN" v-show="visibleList.grd_contract_afterDate==true">
              <thead>
                <tr>
                  <th>계좌번호</th>
                  <th>상품명</th>
                  <th>전일신탁잔고</th>
                  <th>입금금액</th>
                  <th>해지금액</th>
                  <th>잔고금액</th>
                  <th>계좌상태</th>
                  <th>대리인여부</th>
                  <th>대리인</th>
                  <th>위임기간</th>
                  <th>계약일</th>
                  <th>만기일</th>
                  <th>관리점</th>
                  <th>관리자</th>
                </tr>
              </thead>
              <tbody>
                <!-- ds_contract_afterDate -->
                <tr v-for="(row, i) in ds_contract_afterDate" :key="i">
                  <td> {{$tsUtil.gfn_accnoReplace(row.acctNo)}} </td> <!-- 1000087-001-001 -->
                  <td class="aL"> {{row.gdNm}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.trstAmt))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.totAddAmt))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.tmtlTcurAmt))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.trstAmt).toFixed(2))}} </td>
                  <td> <mo-badge class="badge-type possible type03" :text="row.accStatNm" /> </td>
                  <td> {{row.agntRegYn}} </td>
                  <td> {{row.agntNm}} </td>
                  <td> {{$tsUtil.gfn_dateReplace(1, row.athzDate)}} </td>
                  <td> {{$tsUtil.gfn_dateReplace(1, row.contDate)}} </td>
                  <td> {{$tsUtil.gfn_dateReplace(1, row.mtrtDate)}} </td>
                  <td> {{row.mngBrcdNm}} </td>
                  <td> {{row.mngrEmnoNm}} </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td> 합계 </td>
                  <td>  </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_afterDate.trstAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_afterDate.totAddAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_afterDate.tmtlTcurAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_contract_afterDate.trstAmt)}} </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                </tr>
              </tfoot>
            </table>

            <table class="table row-type wsN" v-show="visibleList.grd_assert==true">
              <thead>
                <tr>
                  <th>계좌번호</th>
                  <th>자산유형</th>
                  <th>종목명</th>
                  <th>수량</th>
                  <th>장부금액</th>
                  <th>평가금액</th>
                  <th>평가손익</th>
                  <th>단순수익률</th>
                  <th>연환산수익률</th>
                  <th>매입일</th>
                  <th>만기일</th>
                </tr>
              </thead>
              <tbody>
                <!-- ds_assert -->
                <tr v-for="(row, i) in ds_assert" :key="i">
                  <td> {{$tsUtil.gfn_accnoReplace(row.acctNo)}} </td>   <!-- 1000087-001-001 -->
                  <td class="aL"> {{row.snbGdTcNm}} </td>
                  <td class="aL"> {{row.stndItemCNm}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmAccbQty))}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.accbAmt))}} </td>  
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmAmt))}} </td>  
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmPlSum))}} </td>  
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.simplePrrt).toFixed(2))}} </td>  
                  <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.yrCvrtPrrt).toFixed(2))}} </td>  
                  <td class="aR"> {{$tsUtil.gfn_dateReplace(1, row.trDate)}} </td>  
                  <td> {{$tsUtil.gfn_dateReplace(1, row.mtrtDate)}} </td>  
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td> 합계 </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_assert.accbAmt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(tot_assert.estmAmt)}} </td>
                  <td><!-- tot_assert.estmPlSum --></td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                  <td>  </td>
                </tr>
              </tfoot>
            </table>

          </div>
        </div>

        <!-- popup107 고객 조회 --> 
        <msp-ts-107p
          ref="popup107"
          @ts-popup107-callback="fn_Popup_CallBack"      
          :popupObj="pPopup107Obj"
        ></msp-ts-107p>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    
    <!-- 계좌번호 콤보화면 -->
    <msp-ts-95150D
      ref="search"
      @ts-alert-popup="fn_AlertPopup"
      @ts-fn-init="fn_init"
      @ts-fn-search-list="fn_searchList"
      :ds_search="ds_search"
      :ds_list="ds_list"
    ></msp-ts-95150D>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDvsSearch = { // 디비전에서 사용
    tacno: '',
  }

  const defaultDsList = { // 디비전에서 사용
    csId: '',
    cno: '',
    cnm: '',
    rcnoKindTc: '',
    rcno: '',
    cono: '',
    genTaxtTc: '',
    persnBusiMnBsno: '',
    corpYn: '',
    csIvtdRatingC: '',
    procDt: '',
    valdTrmYys: '',
    ques10: '',
    tacno: '',
    qtnSeq: '',
    age: '',
    tacnoBnkbSeq: '',
    regDate: '',
    tassIssncAccYn: '',
  }

  const defaultDsSearch = { 
    estmStactDate: '',
    tacno: '',
    csId: '',
    accStatC: '',
    pwd: '', //6363
    prrtTyp: '',
    balTyp: '',
    bizDatechk: '',
    Edt_acno: '', // 1000053// 신규추가: 디비전 컴포넌트 태그에서 사용
    edt_cnm: '',            // 신규추가: 디비전 컴포넌트 태그에서 사용
  }

  const defaultDsContract = { 
    acctNo: '',
    gdNm: '',
    rcstPrinWcurAmt: '',
    estmAmt: '',
    estmPlSum: '',
    estmPrrt: '',
    accStatNm: '',
    contDate: '',
    mtrtDate: '',
    mngrEmnoNm: '',
    mngBrcdNm: '',
    chkYn: '',
    trstAmt: '',
    ctno: '',
    agntRegYn: '',
    agntNm: '',
    athzDate: '',
  }

  const defaultDsAssert = { 
    estmStactDate: '',
    acctNo: '',
    snbGdTcNm: '',
    stndItemCNm: '',
    trDate: '',
    mtrtDate: '',
    estmAccbQty: '',
    accbAmt: '',
    estmAmt: '',
    estmPlSum: '',
    simplePrrt: '',
    yrCvrtPrrt: '',
    ctno: '',
  }

  const defaultDsContractBaseDate = { 
    acctNo: '',
    gdNm: '',
    befTrstAmt: '',
    totAddAmt: '',
    tmtlTcurAmt: '',
    trstAmt: '',
    accStatNm: '',
    contDate: '',
    mtrtDate: '',
    mngrEmnoNm: '',
    mngBrcdNm: '',
    chkYn: '',
    ctno: '',
    agntRegYn: '',
    agntNm: '',
    athzDate: '',
  }

  const defaultDsContractAfterDate = { 
    acctNo: '',
    gdNm: '',
    rcstPrinWcurAmt: '',
    estmAmt: '',
    estmPlSum: '',
    estmPrrt: '',
    accStatNm: '',
    contDate: '',
    mtrtDate: '',
    mngrEmnoNm: '',
    mngBrcdNm: '',
    chkYn: '',
    trstAmt: '',
    ctno: '',
    agntRegYn: '',
    agntNm: '',
    athzDate: '',
  }

  const defaultTotContract = {
    trstAmt: 0,
    estmAmt: 0,
    estmPlSum: 0,
  }

  const defaultTotAssert = {
    accbAmt: 0,
    estmAmt: 0,
    estmPlSum: 0,
  }

  const defaultTotContractBaseDate = {
    befTrstAmt: 0,
    totAddAmt: 0,
    tmtlTcurAmt: 0,
    trstAmt: 0,
  }

  const defaultTotContractAfterDate = {
    trstAmt: 0,
    totAddAmt: 0,
    tmtlTcurAmt: 0,
  }

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSComDate from '~/src/ui/ts/comm/TSComDate'
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import MSPTS95150D from '~/src/ui/ts/MSPTS95150D' // 통합계좌
  import MSPTS107P from '~/src/ui/ts/MSPTS107P'       // 계좌번호 조회
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS103M",
    screenId: "MSPTS103M",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,
      'msp-ts-95150D': MSPTS95150D,
      'msp-ts-107p': MSPTS107P,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.fn_afterFormOnload()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {          
          title: '고객별보유현황조회',
        },
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 
        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
        
        // 보안키패드 관련
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear: false,    // 비밀번호 보안키패드 초기화 
        lv_masked_val: '',    // 비밀번호 보안키패드 치환값 ****
        
        // 팝업 데이터 객체
        pPopup107Obj: {},     // 계좌번호 조회
        pAlertPopupObj: {},   // 공통 객체

        // 데이터셋
        ds_search: Object.assign({}, defaultDsSearch), 
        ds_contract: [], //Object.assign({}, defaultDsContract), 
        ds_assert: [], //Object.assign({}, defaultDsAssert), 
        ds_contract_baseDate: [], //Object.assign({}, defaultDsContractBaseDate), 
        ds_contract_afterDate: [], //Object.assign({}, defaultDsContractAfterDate), 
        ds_list: Object.assign({}, defaultDsList), 

        sSvcID: '',          
        acctNo: '', 
        tacno: '', 
        bnkbSeq: '', 
        accSeq: '', 
        ctno: '', 
        gBizDate: '', 
        cal_baseDate: '',    // 보유일자
        rdo_selType: '1',    // 구분
        rdo_accStatC: '%',   // 계좌상태
        rdo_prrtTyp: '1',    // 수익률
        rdo_balTyp: 'Y',     // 무잔고 포함
        prrtTypText:'단순수익률',
        tot_contract: Object.assign({}, defaultTotContract), 
        tot_assert: Object.assign({}, defaultTotAssert), 
        tot_contract_baseDate: Object.assign({}, defaultTotContractBaseDate), 
        tot_contract_afterDate: Object.assign({}, defaultTotContractAfterDate), 
        visibleList: {
          grd_contract: '',
          grd_assert: '',
          grd_contract_baseDate: '',
          grd_contract_afterDate: '',
          Static04: '',
          rdo_prrtTyp: '',
        }
    
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      list_rdo_selType() { // 구분
        let rtn = [];
        rtn.push({value: '1', text: '계약'});
        rtn.push({value: '2', text: '자산'});
        return rtn;
      },
      list_rdo_accStatC() {
        let rtn = [];
        rtn.push({value: '%', text: '전체'});
        rtn.push({value: '11', text: '정상'});
        return rtn;
      },
      list_rdo_balTyp() {
        let rtn = [];
        rtn.push({value: 'Y', text: '예'});
        rtn.push({value: 'N', text: '아니오'});
        return rtn;
      },
      list_rdo_prrtTyp() {
        let rtn = [];
        rtn.push({value: '1', text: '단순수익률'});
        rtn.push({value: '2', text: '연환산수익률'});
        return rtn;
      },

      // modal
      modal1() {return this.$refs.modal1},
      modal2() {return this.$refs.modal2},
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val        
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_pwd'].$el.classList.remove('error')
      }, 
      
      /**********************************************************************************
       * gfn_setInitForm의  callback 함수 : 실질적 form_onload 구현
       **********************************************************************************/
      fn_afterFormOnload ()
      {
          this.fn_init();	
        
        // 비밀번호 사용시 미리 폰패드 오픈 
        //this.div_device.fn_phonePadOpen();
        
        // if(_P_PARAMS){ 
        //   this.Div01.Div00_tacno._setValue( _P_PARAMS.tacno);
        //   _P_PARAMS = false;
        //   }    
        // if ( !TSCommUtil.gfn_isNull(this.Div01.Div00_tacno._getTacno()) )
        // {
        //   this.fn_searchList();
        // }
      },

      /**********************************************************************************
       * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출
       **********************************************************************************/
      fn_init ()
      {
        
        this.gBizDate = this.lv_basInfo.busyDate
        
        // 데이터셋 초기화
        Object.assign(this.ds_list , defaultDsList)
        Object.assign(this.ds_search, defaultDsSearch)
        this.ds_contract = [] 
        this.ds_assert = [] 
        this.ds_contract_baseDate = [] 
        this.ds_contract_afterDate = [] 

        //버튼 설정 초기화
       
        this.Div01_rdo_selType_onitemchanged();
        
        this.rdo_selType = this.list_rdo_selType[0].value
        this.rdo_accStatC = this.list_rdo_accStatC[1].value
        this.rdo_prrtTyp = this.list_rdo_prrtTyp[1].value
        this.ds_search.pwd = ''                           // 비밀번호 초기화
        this.lv_isClear = !this.lv_isClear ? true : false // 비밀번호 보안키패드 초기화
        
        //날자설정 및 Combo 설정
        this.cal_baseDate = TSCommUtil.gfn_dateReplace(1, this.lv_basInfo.prevBusyDate) 
        
        this.visibleList.grd_contract = false
        this.visibleList.grd_assert = false
        this.visibleList.grd_contract_baseDate = true 
        this.visibleList.grd_contract_afterDate = false  
        
        this.visibleList.Static04 = true    
        this.visibleList.rdo_prrtTyp = true 

        this.fn_rmAllErrCss() // css error 초기화 
      },

      /**********************************************************************************
       * 조회 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_searchList ()
      {

        var sOutDatasets = "";
          
        //조회 데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
        //var nRow = this.ds_search.addRow();	
        
        if ( TSCommUtil.gfn_isNull(this.$refs.search._getTacno())) {
          // this.fn_addValidErr(this.$refs.Edt_acno.$el)
          // this.$refs.Edt_acno.focus()
          // TSCommUtil.gfn_validate(this, '종합계좌번호를 선택하십시오.')
          TSCommUtil.fn_validate( this, [{ content: '종합계좌번호를 선택하십시오.', ref:'Edt_acno' }] )
          return;
        }
          
        if ( TSCommUtil.gfn_isNull(this.cal_baseDate) ) //20100414
        {
          // this.fn_addValidErr(this.$refs.cal_baseDate.$el)
          // TSCommUtil.gfn_validate(this, '기준일자를 입력하십시오.')
          TSCommUtil.fn_validate( this, [{ content: '기준일자를 입력하십시오.', ref:'edt_cal_baseDate' }] )
          return;
        }
        
        if ( TSCommUtil.gfn_isNull(this.ds_search.pwd) ) {
          // this.fn_addValidErr(this.$refs.edt_pwd.$el)
          // TSCommUtil.gfn_validate(this, '비밀번호를 입력하십시오.')
          TSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시오.', ref:'edt_pwd' }] )
          return false;
        }
        
        let numBaseDate = Number ( this.cal_baseDate.replaceAll("-","") ) 
        let numBizDate = Number ( this.gBizDate ) 

        if (this.rdo_selType == this.list_rdo_selType[0].value ) {    //계약별
          if ( numBaseDate == numBizDate ) {
            this.visibleList.grd_contract = false
            this.visibleList.grd_assert = false
            this.visibleList.grd_contract_baseDate = true 
            this.visibleList.grd_contract_afterDate = false 
          }
          else if( numBaseDate < numBizDate ){
            this.visibleList.grd_contract = true  
            this.visibleList.grd_assert = false
            this.visibleList.grd_contract_baseDate = false 
            this.visibleList.grd_contract_afterDate = false
          }
          else{
            this.visibleList.grd_contract = false  
            this.visibleList.grd_assert = false
            this.visibleList.grd_contract_baseDate = false 
            this.visibleList.grd_contract_afterDate = true
          }
        }
        else {   //자산별
          this.visibleList.grd_contract = false
          this.visibleList.grd_assert = true
          this.visibleList.grd_contract_baseDate = false 
          this.visibleList.grd_contract_afterDate = false
        }
        
        //조회용 dataset에 조회할 항목을 넣어준다.
        this.ds_search.estmStactDate = numBaseDate
        this.ds_search.tacno = this.$refs.search._getTacno()
        this.ds_search.csId = this.$refs.search._getCsId()
        this.ds_search.accStatC = this.rdo_accStatC
        //this.ds_search.pwd = this.edt_pwd
        this.ds_search.balTyp = this.rdo_balTyp
        this.ds_search.prrtTyp = this.rdo_prrtTyp
        
        if(numBaseDate > numBizDate){  	 //미래일자
            this.ds_search.bizDatechk = 'after'
        }
        if(numBaseDate < numBizDate){  	 //과거일자
            this.ds_search.bizDatechk = 'before'
        }
        
        // po호출
        this.ds_contract = [] //Object.assign(this.ds_contract, defaultDsContract)
        this.ds_assert = [] //Object.assign(this.ds_assert, defaultDsAssert)
        this.ds_contract_baseDate = [] //Object.assign(this.ds_contract_baseDate, defaultDsContractBaseDate)
        this.ds_contract_afterDate = [] //Object.assign(this.ds_contract_afterDate, defaultDsContractAfterDate)

        let targetObj = '' 
        let eaiId = ''
        if ( this.rdo_selType == this.list_rdo_selType[0].value ) {   //계약별
          if (numBaseDate == numBizDate) {
            // sSvcID = "selectTFSL80700AList" ;                      // 당일자
            // sOutDatasets  = "ds_contract_baseDate=tfsl80700List";
            eaiId = 'C392_F10600256_S'
            targetObj = 'ds_contract_baseDate'
          }
          else if(numBaseDate < numBizDate){		
            // sSvcID = "selectTFSL80700AList" ;                      // 전일자
            // sOutDatasets  = "ds_contract=tfsl80700List";
            eaiId = 'C392_F10600256_S'
            targetObj = 'ds_contract'
          }
          else{
            // sSvcID = "selectTFSL80700AList" ;                      //미래일자
            // sOutDatasets  = "ds_contract_afterDate=tfsl80700List";
            eaiId = 'C392_F10600256_S'
            targetObj = 'ds_contract_afterDate'
          }
        }
        else {   //자산별
          // sSvcID = "selectTFSL80700List" ;                    
          // sOutDatasets  = "ds_assert=tfsl80700List";
          eaiId = 'C392_F10600185_S'
          targetObj = 'ds_assert'
        }
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = eaiId
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        this.eaiCommObj.params.targetObj = targetObj // fn_callBack 함수 분기값. po에서는 쿼리에 사용 안함
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)

        /*서비스 호출시 정보 세팅 */
        // var sURL          = "svc::"+sSvcID+".do";	// svc = http://localhost:8080/
        // var sInDatasets   = "tfsl80700VO=ds_search:";    // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack"; // transaction의 결과를 돌려줄 Function의 이름입니다.
        
        /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      fn_callBack ( res ) {
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        let targetObj = res.params.targetObj

        if ( targetObj == 'ds_contract_baseDate' ) {           // sOutDatasets  = "ds_contract_baseDate=tfsl80700List";
          this.ds_contract_baseDate = resData.tfsl80700
        } else if ( targetObj == 'ds_contract' ) {             // sOutDatasets  = "ds_contract=tfsl80700List";
          this.ds_contract = resData.tfsl80700
        } else if ( targetObj == 'ds_contract_afterDate' ) {   // sOutDatasets  = "ds_contract_afterDate=tfsl80700List";
          this.ds_contract_afterDate = resData.tfsl80700
        } else if ( targetObj == 'ds_assert' ) {               // sOutDatasets  = "ds_assert=tfsl80700List";
          this.ds_assert = resData.tfsl80700
        }
        
        this.prrtTypText = this.rdo_prrtTyp=='2' ? '연환수익률' : '단순수익률'
      },
      
      /************************************************************************************************
       * 계좌번호 입력 시 
       ************************************************************************************************/
      // fn_searchValid 로 대체
      // Edt_acno_onkeyup(event, el) { 
      //   console.log('Edt_acno_onkeyup.....')
      //   // var acnoValue = this.Edt_acno.value;
      //   let acnoValue = event.target.value
      //   const _el = this.$refs[el].$el

      //   // 계좌번호 10자리 입력시 자동 조회 
      //   if ( acnoValue.trim().length == 7 ) {
      //     Object.assign(this.ds_list, defaultDsList), 
      //     // Object.assign(this.dvs_search, defaultDvsSearch), 
      //     this.$refs.search.fn_transaction(acnoValue)
      //     this.fn_validErrRemoveInput(event, 'Edt_acno') // error class 삭제 체크
      //   }
      //   else {
      //     // 계좌번호 입력 후 엔터 시 계좌 조회 tr 호출 
      //     if ( event.key == 'Enter' ) {
      //       if ( acnoValue.trim().length != 7 ) {
      //         TSCommUtil.gfn_validate(this, '계좌번호를 다시 입력하시오.')
      //         _el.focus() // 포커스
      //         if ( ! _el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
      //           _el.classList.add('error')
      //         }
      //       }
      //       else {
      //         this.$refs.search.fn_transaction(acnoValue)
      //         this.fn_validErrRemoveInput(event, 'Edt_acno') // error class 삭제 체크
      //       }
      //     }
      //   }
      // },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회 
       ******************************************************************************/
      fn_SearchValid (event, flag) {
        console.log('fn_SearchValid.....')

        let t_acno = ''
        if( flag == 'P' ) {
          t_acno = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,13)
        } else {
          if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
            return 
          }
          if( !TSCommUtil.gfn_isNull(this.ds_search.Edt_acno) ) {
            t_acno = this.ds_search.Edt_acno.replace(/[^0-9]/g, '').substr(0,7)              
          }
        }
        
        if( TSCommUtil.gfn_length(t_acno) == 7 ) {  

          if(!TSCommUtil.gfn_isNum(t_acno) ) {

            TSCommUtil.fn_validate( this, [{ content: '종합계좌번호 형식에 맞지 않습니다.', ref:'Edt_acno' }] )
            // TSCommUtil.gfn_validate(this, '종합계좌번호 형식에 맞지 않습니다.')

            // const _el = this.$refs['Edt_acno'].$el
            // _el.focus() // 포커스
            // if ( ! _el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
            //   _el.classList.add('error')
            // }
          } else {
            this.fn_validErrRemoveInput(event, 'Edt_acno')  // error class 삭제 체크
            this.$refs.search.fn_transaction(t_acno)
          }
        }

      },

      /************************************************************************************************
       * 패스워드 입력 시 
       ************************************************************************************************/
      pwd_onkeyup(event, el) {
        console.log('pwd_onkeyup.....')
        // var acnoValue = this.Edt_acno.value;
        let acnoValue = event.target.value
        const _el = this.$refs[el].$el
        
        if ( acnoValue.trim().length == 4 ) {
          // Object.assign(this.dvs_search, defaultDvsSearch), 
          this.fn_validErrRemoveInput(event, 'edt_pwd') // error class 삭제 체크
          this.fn_searchList()
        }
        
      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 계좌조회 
       ******************************************************************************/
      fn_OpenPopup(type, flag) {
        switch (type) {
          case 0:
            this.$refs.popup107.fn_Open()
            break          
        }
      },
     
      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : 고객조회(107p) 컴포넌트에서 callback
       ******************************************************************************/
      fn_Popup_CallBack ( pData ) {
        
        if(TSCommUtil.gfn_isNull(pData.cnm)){
          return;
        }

        this.ds_list.csId = pData.csId           				                // 고객ID
        this.ds_list.cno  = pData.tacno            				              // 종합계좌번호
        this.ds_search.Edt_acno = pData.tacno                           // 종합계좌번호
        this.ds_list.cnm  = pData.cnm            					              // 실명
        this.ds_list.rcnoKindTc = pData.rcnoKindTc           		        // 실명구분 
        this.ds_list.rcno = pData.rcno           				                // 실명번호 
        this.ds_list.cono = pData.cono           				                // 사업자번호  
        this.ds_list.genTaxtTc  = pData.genTaxtTc            		        // 일반과세구분코드 
        this.ds_list.persnBusiMnBsno = pData.persnBusiMnBsno            // 개인사업자사업자등록번호 
        this.ds_list.corpYn = pData.corpYn           			              // 법인여부 
        this.ds_list.csIvtdRatingC = pData.csIvtdRatingC            	  // 고객투자성향등급코드 
        this.ds_list.procDt = pData.procDt           			              // 처리일시  
        this.ds_list.valdTrmYys = pData.valdTrmYys           		        // 유효기간년수 
        this.ds_list.ques10 = pData.ques10           			 	            // 파생상품투자기간 
        this.ds_list.tacno  = pData.tacno            				            // 종합계좌번호 
        this.ds_list.age  = pData.age            			    	            // 나이 
        this.ds_list.tacnoBnkbSeq = pData.tacnoBnkbSeq          			  // 종합계좌번호 + 통장일련버호 
        this.ds_list.regDate  = pData.regDate            				        // 고객가입일자
        this.ds_list.tassIssncAccYn = pData.tassIssncAccYn           		// TASS발급계좌여부
        this.Edt_acno = pData.tacno                                     // 종합계좌번호 
        this.edt_regDate = pData.regDate                                // 고객가입일자 
      },

      Div01_rdo_selType_onitemchanged ()
      {
        let numBaseDate = Number ( this.cal_baseDate.replaceAll("-","") ) 
        let numBizDate = Number ( this.gBizDate ) 

        if ( this.rdo_selType == this.list_rdo_selType[0].value ) {    //계약별
            if (numBaseDate == numBizDate) {
              this.visibleList.grd_contract = false
              this.visibleList.grd_assert = false
              this.visibleList.grd_contract_baseDate = true 
              this.visibleList.grd_contract_afterDate = false 
            }
            else if(numBaseDate < numBizDate){
              this.visibleList.grd_contract = true  
              this.visibleList.grd_assert = false
              this.visibleList.grd_contract_baseDate = false 
              this.visibleList.grd_contract_afterDate = false
            }
            else{
              this.visibleList.grd_contract = false  
              this.visibleList.grd_assert = false
              this.visibleList.grd_contract_baseDate = false 
              this.visibleList.grd_contract_afterDate = true
            }
            
          this.visibleList.Static04 = true    //수익률 라디오버튼
          this.visibleList.rdo_prrtTyp = true //수익률 라디오버튼    
        }  
        else {   //자산별
          this.visibleList.grd_contract = false
          this.visibleList.grd_assert = true
          this.visibleList.grd_contract_baseDate = false //WYJ 일단안보이게
          this.visibleList.grd_contract_afterDate = false
          this.visibleList.Static04 = false    //수익률 라디오버튼
          this.visibleList.rdo_prrtTyp = false //수익률 라디오버튼
        }
      },

      /******************************************************************************
       * Function명 : fn_validErrRemoveInput
       * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveInput (event, el) {
        const value = event.currentTarget.value
        const _el = this.$refs[el].$el
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
          _el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()     
          break
        }        
      },

      /******************************************************************************
       * Function명 : fn_addValidErr
       * 설명       : 에러 클래스 추가
      ******************************************************************************/      
      fn_addValidErr (lv_el) {
        if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
          lv_el.classList.add('error')
        }
        lv_el.focus()
      },

      /******************************************************************************
       * Function명 : fn_totalSum
       * 설명       : 토탈값 계산
      ******************************************************************************/      
      fn_totalSum (list, key) {
        let total = 0
        
        for(idx in list) {
          if ( list[idx][key] != undefined ) 
            total += Number(list[idx][key])
        }
        
        return total
      },

      /******************************************************************************
      * Function명 : fn_rmAllErrCss
      * 설명       : 해당 컴포넌트 error css 삭제
      ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
            this.$refs[idx].$el.classList.remove("error")
          }
        }
      },
      
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      rdo_selType : {
        handler: function(val, oldVal) { 
          this.Div01_rdo_selType_onitemchanged()
        },
      },
      rdo_accStatC : {
        handler: function(val, oldVal) { 
          this.Div01_rdo_selType_onitemchanged()
        },
      },
      ds_contract_baseDate : {
        handler: function(val, oldVal) { 
          // 토탈값 초기화
          Object.assign(this.tot_contract_baseDate, defaultTotContractBaseDate)
          for ( let i in val  ) {
            this.tot_contract_baseDate.befTrstAmt += Number(val[i].befTrstAmt)
            this.tot_contract_baseDate.totAddAmt += Number(val[i].totAddAmt)
            this.tot_contract_baseDate.tmtlTcurAmt += Number(val[i].tmtlTcurAmt)
            this.tot_contract_baseDate.trstAmt += Number(val[i].trstAmt)
          }
        },
        deep: true
      },
      ds_contract : {
        handler: function(val, oldVal) { 
          // 토탈값 초기화
          Object.assign(this.tot_contract, defaultTotContract)
          for ( let i in val  ) {
            this.tot_contract.trstAmt += Number(val[i].trstAmt)
            this.tot_contract.estmAmt += Number(val[i].estmAmt)
            this.tot_contract.estmPlSum += Number(val[i].estmPlSum)
          }
        },
        deep: true
      },
      ds_contract_afterDate : {
        handler: function(val, oldVal) { 
          // 토탈값 초기화
          Object.assign(this.tot_contract_afterDate, defaultTotContractAfterDate)
          for ( let i in val  ) {
            this.tot_contract_afterDate.trstAmt += Number(val[i].trstAmt)
            this.tot_contract_afterDate.totAddAmt += Number(val[i].totAddAmt)
            this.tot_contract_afterDate.tmtlTcurAmt += Number(val[i].tmtlTcurAmt)
          }
        },
        deep: true
      },
      ds_assert : {
        handler: function(val, oldVal) { 
          // 토탈값 초기화
          Object.assign(this.tot_assert, defaultTotAssert)
          for ( let i in val  ) {
            this.tot_assert.accbAmt += Number(val[i].accbAmt)
            this.tot_assert.estmAmt += Number(val[i].estmAmt)
            this.tot_assert.estmPlSum += Number(val[i].estmPlSum)
          }
        },
        deep: true
      },
      // 'ds_search.pwd' : {
      //   handler: function(val, oldVal) { 
      //     if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
      //      this.$refs.edt_pwd.el.classList.remove('error')
      //   },
      //   deep: true
      // },
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
